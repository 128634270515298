import store from "@/store";

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useCustomersEdit() {
  const { router } = useRouter();
  const toast = useToast();

  const editCustomer = (editCompany) => {
    editCompany.country = editCompany.country.value
      ? editCompany.country.value
      : editCompany.country;
    editCompany.status = editCompany.status.value
      ? editCompany.status.value
      : editCompany.status;
    editCompany.business_domain = editCompany.business_domain.value
      ? editCompany.business_domain.value
      : editCompany.business_domain;
    store
      .dispatch("app-customer/editCustomer", { editCompany })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        // redirect to list page
        router.push({ name: "customer-list" });
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error updating Customer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const fetchCustomerByID = (customer) => {
    const customerID = customer.value.ID;
    store
      .dispatch("app-customer/fetchCustomerByID", { customerID })
      .then((response) => {
        customer.value = response.data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching customer detail",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    editCustomer,
    fetchCustomerByID,
  };
}

<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <customer-edit-form-wizard :edit-company="editCompany" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import CustomerEditFormWizard from "./CustomerEditFormWizard.vue";
import useCustomersEdit from "./useCustomersEdit";
import customerStoreModule from "../customerStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    CustomerEditFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const editCompany = ref({});

    const CUSTOMER_APP_STORE_MODULE_NAME = "app-customer";

    const { fetchCustomerByID } = useCustomersEdit();

    // Pull customerID from router
    const { route } = useRouter();
    const customerID = route.value.params.id;

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });

    editCompany.value.ID = customerID;
    fetchCustomerByID(editCompany);

    return {
      editCompany,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
